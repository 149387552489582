<template>
  <div class="box" v-if="loadFlag">
    <div class="header">
      <img
        @click="goback"
        class="back"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
        alt=""
      />
      <div class="title">语音互动</div>
    </div>
    <div class="horn">
      <img
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/horn.png"
        alt=""
      />
    </div>
    <div class="range">
      <span class="range-text">音量</span
      ><input
        class="range-input"
        type="range"
        name="range"
        min="0"
        max="100"
        step="1"
        :value="this.volume"
        @change="this.changeVoice"
        :style="{ backgroundSize: volume + '% 100%' }"
      />
    </div>
    <div class="operation">
      <div class="operation-div-left">
        <div class="voice" @click="gotoVoice">
          <img
            v-if="voiceImg"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/voice.png"
            alt=""
          />
          <img
            v-if="!voiceImg"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/voice-touch.png"
            alt=""
          />
          <p>语音播报</p>
        </div>
      </div>
      <div class="operation-div-left">
        <div class="whistle" @click="whistle">
          <img
            v-if="whistleImg"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/whistle.png"
            alt=""
          />
          <img
            v-if="!whistleImg"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/whistle-touch.png"
            alt=""
          />
          <p>鸣笛</p>
        </div>
      </div>

      <div
        class="operation-div-left"
        v-if="cabinetType === 'S' && VinType === 'A'"
      >
        <div class="adv" @click="handleAdv">
          <img
            v-if="advImg"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/adv.png"
            alt=""
          />
          <img
            v-if="!advImg"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/adv-touch.png"
            alt=""
          />
          <p>49寸广告屏</p>
        </div>
      </div>
    </div>
    <!-- 49寸广告屏弹窗 -->
    <div
      :class="advModal ? 'open-modal open-modalShow' : 'open-modal'"
      @click="handleHiddenAdv"
    ></div>
    <div
      :class="
        advModal
          ? 'open-modal-content open-modal-contentShow'
          : 'open-modal-content'
      "
    >
      <p class="title">广告屏状态</p>
      <div class="content-btn">
        <p class="cancel" @click="handleOpen(1)">开启</p>
        <p class="cancel sure" @click="handleOpen(0)">关闭</p>
      </div>
    </div>
  </div>
</template>

<script>
import { playWhistle, speakerVolumeControl, adControl } from '@/api/api';
export default {
  name: 'Voice',
  components: {},
  data() {
    return {
      voiceImg: true,
      whistleImg: true,
      loadFlag: false,
      volume: 50,
      advImg: true,
      advModal: false,
      cabinetType: '',
      VinType: '',
      queryID: ''
    };
  },

  created: function () {},

  mounted: function () {
    this.cabinetType = this.$route.query.cabinetType;
    this.VinType = this.$route.query.VinType;
    this.vin = this.$route.query.vin;
    // volume新车会上报-1
    this.volume = this.$route.query.volume
      ? Number(this.$route.query.volume) === -1
        ? 100
        : Number(this.$route.query.volume)
      : 50;
    this.queryID = this.$route.query.id

    this.$loadingCircle.start();

    setTimeout(() => {
      this.loadFlag = true;
      this.$loadingCircle.end();
    }, 300);
  },

  methods: {
    goback() {
      if (this.queryID) {
        this.$router.push({
          path: "SecurityVehicle",
          query: {
            vin: this.vin,
            volume: this.volume,
            cabinetType: this.cabinetType,
            VinType: this.VinType
          }
        });
      } else {
        window.location.href = 'neolix://interaction';
      }
    },
    changeVoice(e) {
      this.volume = e.target.value;
      speakerVolumeControl({
        vin: this.vin,
        volume: this.volume,
      }).then((res) => {
        // this.$toast("音量调节成功");
      });
    },
    whistle() {
      this.whistleImg = false;
      const _self = this;
      setTimeout(function () {
        _self.whistleImg = true;
      }, 200);
      playWhistle({
        vin: this.$route.query.vin,
      }).then((res) => {
        this.$toast('已鸣笛', { duration: 1500 });
      });
    },
    gotoVoice() {
      this.voiceImg = false;
      const _self = this;
      setTimeout(function () {
        _self.$router.push({
          path: `choose?vin=${_self.$route.query.vin}&volume=${_self.volume}&cabinetType=${_self.cabinetType}&VinType=${_self.VinType}`,
        });
      }, 200);
    },
    handleAdv() {
      this.advImg = false;
      setTimeout(() => {
        this.advImg = true;
      }, 200);
      this.advModal = true;
    },
    handleOpen(type) {
      adControl({
        vin: this.$route.query.vin,
        control: type, //  0 ：关闭 1：打开
      }).then((res) => {
        console.log(res, '...res');
        this.$toast(`${type === 0 ? '关闭' : '开启'}指令已下发`, {
          duration: 1500,
        });
        this.advModal = false;
      });
    },
    handleHiddenAdv() {
      this.advModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 100%;
  background: #ffffff;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  font-weight: 500;
  * {
    -webkit-user-select: none; /*禁用手机浏览器的用户选择功能 */
    -moz-user-select: none;
    -webkit-touch-callout: none;
    -ms-user-select: none; /*IE10*/
    user-select: none;
  }
  input,
  textarea {
    -webkit-user-select: auto; /*webkit浏览器*/
    margin: 0px;
    padding: 0px;
    outline: none;
  }
  .header {
    height: 40px;
    line-height: 40px;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 100;
  }
  .back {
    width: 32px;
    height: 32px;
    margin: 4px 11px;
  }
  .title {
    font-size: 15px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 500;
    text-align: center;
    width: 100%;
    min-width: 100px;
    margin-right: 54px;
  }
  .range {
    margin: 50px 0;
    height: 25px;
    line-height: 25px;
    padding: 0 30px;
    display: flex;
  }
  .range-text {
    padding-right: 30px;
    flex: 1;
  }
  .range-input {
    color: red;
  }
  input[type='range'] {
    margin-top: 9px; /*上部分的填充值*/
    outline: none;
    width: calc(100% - 66px);
    -webkit-appearance: none; /*清除系统默认样式*/
    background: -webkit-linear-gradient(#436eff, #436eff) no-repeat, #e3e6f0; /*背景颜色，俩个颜色分别对应上下，自己尝试下就知道了嗯*/
    height: 5px; /*横条的高度，细的真的比较好看嗯*/
    border-radius: 6px;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none; /*清除系统默认样式*/
    height: 21px; /*拖动块高度*/
    width: 21px; /*拖动块宽度*/
    background: #436eff;
    border-radius: 50%; /*外观设置为圆形*/
  }
  .horn {
    width: 100%;
    height: auto;
    margin-top: 40px;
    min-height: 280px;
  }
  .horn img {
    width: 100%;
    background-color: #f4f6fd;
  }
  .operation-div-left {
    // flex: 1;
    width: 50%;
  }
  .operation-div-left:nth-child(3) {
    margin-top: 30px;
  }
  .voice,
  .whistle,
  .adv {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
  }

  .voice img,
  .whistle img,
  .adv img {
    width: 86px;
    height: 86px;
    margin-bottom: 9px;
  }
  .operation {
    position: fixed;
    bottom: 46px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .open-modalShow {
    opacity: 1 !important;
    visibility: visible !important;
  }

  .open-modal-contentShow {
    transform: translateY(0) !important;
  }

  .open-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 10;
  }

  .open-modal-content {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0.32rem 0.32rem 0px 0px;
    background: rgba(255, 255, 255, 1);
    overflow: hidden;
    transform: translateY(100%);
    transition: transform 0.3s;
    z-index: 11;
    .title {
      font-size: 0.4rem;
      font-weight: 600;
      color: rgba(34, 34, 34, 1);
      text-align: center;
      padding: 0.48rem 0;
    }
    .content-btn {
      display: flex;
      justify-content: space-evenly;
      padding-bottom: 1.04rem;
      .cancel {
        padding: 0.2rem 1.3rem;
        font-size: 0.28rem;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        border-radius: 48px;
        background: rgba(67, 110, 255, 1);
      }
      .sure {
        background: rgba(250, 83, 71, 1);
      }
    }
  }
}
</style>
